import { gql } from '@apollo/client';

import { NATIVE_VIDEO_FRAGMENT, YOUTUBE_VIDEO_FRAGMENT } from '~/v1/graphql/fragments/video';

import { INLINE_IMAGES_FRAGMENT } from './components/inlineImages';
import { PRESIDENTIAL_QUOTE_FRAGMENT } from './presidentialQuote';
import { QUICK_LINK_FRAGMENT } from './quickLink';
import { QUOTE_FRAGMENT } from './quote';
import { SPOTLIGHT_FRAGMENT } from './spotlight';

export const ARTICLE_RICH_TEXT_FRAGMENT = gql`
  fragment ArticleRichText on ArticleRichText {
    includedAssets {
      ...Media
    }
    includedEntries {
      id
      data {
        ...Quote
        ...PresidentialQuote
        ...InlineImages
        ...Spotlight
        ...QuickLink
        ...NativeVideo
        ...YouTubeVideo
      }
    }
    value
  }
  ${QUOTE_FRAGMENT}
  ${PRESIDENTIAL_QUOTE_FRAGMENT}
  ${INLINE_IMAGES_FRAGMENT}
  ${SPOTLIGHT_FRAGMENT}
  ${QUICK_LINK_FRAGMENT}
  ${NATIVE_VIDEO_FRAGMENT}
  ${YOUTUBE_VIDEO_FRAGMENT}
`;
