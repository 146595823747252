import { gql } from '@apollo/client';

import { GRANT_MAKING_AREA_FRAGMENT } from './grantMakingArea';
import { QUOTE_FRAGMENT } from './quote';

export const PRESIDENTIAL_QUOTE_FRAGMENT = gql`
  fragment PresidentialQuote on PresidentialQuoteCallOut {
    presidentialCtaText
    presidentialHeading
    presidentialText
    presidentialTitle
    quote {
      ...Quote
    }
    grantMakingArea {
      ...GrantMakingArea
    }
    searchQuery
  }
  ${QUOTE_FRAGMENT}
  ${GRANT_MAKING_AREA_FRAGMENT}
`;
